.addProductPage {
  height: 100vh;
  overflow: scroll;
  width: 100vw;
  position: absolute;
}

.addProductTimeoutLoginOverlay {
  position: fixed;
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 2000;
  background: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  overflow: hidden;
  touch-action: none;
}

.addProductTimeoutLoginDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  height: fit-content;
  margin: 0 10px;
}

.addProductTimeoutLoginTextDiv {
  border-radius: 15px;
  font-family: 'Poppins';
  text-align: center;
  font-size: 14px;
  background-color: #C9DCB3;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 15px;
}

@media only screen and (min-width: 450px) {
  .addProductTimeoutLoginTextDiv {
    width: 439px;
  }
}

.addProductGradientCanvas {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  height: 120%;
  width: 150%;
  margin: 0;
  filter: blur(40px);
  background: black;
  z-index: -1;
}

.addProductHeader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;

  width: 100%;
  height: 80px;
  z-index: 1;
}

.addProductHeadingDiv {
  display: flex;
  align-items: center;
  justify-content: center;

  white-space: nowrap;
  border-radius: 15px;
  padding: 5px 10px;
  margin-left: 5px;
  height: 45px;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.8);
  background-color: #f57225;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
}

.addProductHeading {
  font-family: 'Poppins', sans-serif;
  height: fit-content;
  margin: 0px;
  font-weight: 900;
  color: #8E3501;
  cursor: default;
}

.addProductForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  position: absolute;
  top: 80px;
  height: 100%;
  width: 100%;
  padding: 0 8px;
  box-sizing: border-box;
}

.vendorBack {
  white-space: nowrap;
  border-radius: 15px;
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.8);
  background-color: #f57225;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
}

.vendorBackIcon {
  width: 35px;
  height: 35px;
  padding: 5px 15px;
  filter: opacity(0.6);
}

.vendorBack:hover {
  filter: brightness(50%);
  box-shadow: none;
}

.vendorBackIcon:hover {
  filter: opacity(0.8);
}

hr {
  border-radius: 5px;
  margin-bottom: 25px;
}

.pricingBreakdown, .addProductDeliveryMethodsContainer {
  z-index: 4;
}

.necessaryInfo, .pricingBreakdown, .addProductDeliveryMethodsContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: rgba(208, 215, 215, 0.4);
  border: solid rgba(255, 255, 255, 0.1) 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  border-radius: 20px;
  margin: 10px;
  width: 98%;
  box-sizing: border-box;
}

.necessaryInfo {
  z-index: 5;
  height: fit-content;
  padding: 10px;
}

.numberInput {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 40px;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 5px;
}

.quantityText {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  text-align: left;
  padding-left: 0;
  height: 40px;
  margin-bottom: 0px;
  color: rgba(0, 0, 0, 0.714);
  cursor: default;
}

.randSymbol {
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  text-align: left;
  padding-left: 0;
  height: 40px;
  margin-bottom: 0px;
  margin-right:0px;
  color: rgba(0, 0, 0, 0.714);
  cursor: default;
}

#quantity {
  margin-left: 5px;
  font-family: 'Poppins', sans-serif;

  background: #f5f5f5;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border: 0px;
  text-align: center;
}

#price {
  background: #f5f5f5;
  width: 50px;
  margin-left: 5px;
  height: 30px;
  border-radius: 5px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border: 0px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.priceErrorMessage {
  box-sizing: border-box;
  background: #E87674;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  margin: 5px 0 10px 0;
  font-family: 'Poppins';
  width: 100%
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; 
}

#title {
  background: #f5f5f5;
  width: 100%;
  height: 30px;
  border-radius: 10px;
  padding-left: 5px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border: 0px;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
}

.categoriesDiv {
  width: 100%;
  margin-bottom: 10px;
}


#description {
  background: #f5f5f5;
  resize: none;
  min-width: 100%;
  max-height: 130px;
  min-height: 130px;
  border-radius: 10px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border: 0px;
  font-family: 'Poppins', sans-serif;
  padding: 5px;
  box-sizing: border-box;
}

.pricingBreakdownInfoDiv {
  text-align: left;
  border-radius: 10px;
  padding: 10px;
  background-color: white;
  color: black;
  margin: 0 10px;
  font-family: 'Poppins';
  z-index: 1;
  font-size: 14px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px

}

.pricingContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  gap: 10px;
  box-sizing: border-box;
}

.sellerEarningsContainer, .listedPriceContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: 'Poppins';
  background-color: rgba(56, 56, 56, 0.3);
  color: rgba(0, 0, 0, 0.798);
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  padding: 0 10px;
}

.progressBar {
  width: 130px;
  height: 10px;
  border-radius: 5px;
  position: absolute;
  z-index: 3;
  -webkit-appearance: none;
  appearance: none;
  bottom: 20px;
  left: 10px;
}

.progressBar::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

.progressBar::-moz-progress-bar {
  background-color: rgba(238, 238, 238, 0.651);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

.progressBar::-webkit-progress-value {
  background-color: #D75759;
  box-shadow: 1px 1px 5px 3px rgba( 0, 0, 0, 0.1 );
  border-radius: 5px;
}

.progressBar::-moz-progress-value {
  background-color: #D75759;
  box-shadow: 1px 1px 5px 3px rgba( 0, 0, 0, 0.1 );
  border-radius: 5px;
}

.uploadProductMediaDiv {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  margin: 10px;
  padding: 10px;

  z-index: 4;
  height: fit-content;
  width: 98%;
  background: rgba(208, 215, 215, 0.4);
  border: solid rgba(255, 255, 255, 0.1) 2px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-sizing: border-box;
}

.addProductErrorMessage {
  font-family: 'Poppins';
  font-size: 12px;
  background-color: rgba(226, 70, 70, 0.873);
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
}

.uploadMediaButton {
  font-family: 'Poppins';
  font-size: 14px;
  background-color: black;
  border-radius: 10px;
  color: white;
  margin-left: 10px;
  cursor: pointer;
  border: none;
}

.addProductPlusIcon {
  margin-left: 10px;
  width: 16px;
  height: 16px;
  filter: invert(1);
}

.addProductPlusIcon:hover {
  filter: invert(32%) sepia(79%) saturate(1574%) hue-rotate(5deg) brightness(102%) contrast(107%);
}

#inputField {
  display: none;
}

.submitButton, .saveDraftButton {
  background-color: black;
  font-family: 'Poppins';
  font-size: 16px;
  font-weight: bold;
  color: white;
  border-radius: 10px;
  margin: 5px 0px 50px 10px;
  cursor: pointer;
  border: none;
  padding: 5px 20px;
}

.submitButton:hover, .saveDraftButton:hover {
  filter: invert(1);
}

.submitButton:disabled {
  background-color: rgba(0, 0, 0, 0.6);
  font-family: 'Poppins';
  cursor: not-allowed;
  border: none;
  padding: 5px 20px;
}

#uploadDiv {
  display: flex;
  height: fit-content;
  width: 100%;
  margin-top: 10px;
}

.uploadProductMediaContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
}

.addProductImagePlusBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 200px;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.addProductImagePlusBtnIcon {
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.imageUploadPreviewDiv {
  position: relative;
}

.addProductUploadedImageWrapper {
  position: relative;
}

#uploadedImage1, .imageUploadPreview, .addProductUploadedImage {
  height: 200px;
  width: 150px;
  position: left;
  object-fit: cover;
  border-radius: 10px;
  margin: 10px 15px 10px 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;

  z-index: 1;
}

#imageUploadDeleteBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 2px;
  right: 2px;
  background: rgba(208, 215, 215, 0.6);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 25px -5px, rgba(0, 0, 0, 0.25) 0px 10px 10px -5px;

  margin-left: -25px;
  margin-top: -5px;

  height: 30px;
  width: 30px;
  border-radius: 15px;
  cursor: pointer;
  z-index: 2;
  
}

#imageUploadDeleteBtn:hover {
  filter: invert(1);
}

.imageUploadLoadingDiv {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;

  bottom: -30px;
  right: 10px;

  filter: opacity(50%) brightness(0);
  margin: 40px auto;
  animation: spin 2s infinite linear;
}

.marketLoadingIcon {
  width: 30px;
  height: 30px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* #delete2 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(208, 215, 215, 0.6);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

  margin-left: 322px;
  margin-top: -232px;

  height: 30px;
  width: 30px;
  border-radius: 15px;
  cursor: pointer;
  z-index: 7;
} */

.uploadedImageDeleteIcon {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;

  background: rgba(208, 215, 215, 0.6);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 25px -5px, rgba(0, 0, 0, 0.25) 0px 10px 10px -5px;
  border-radius: 50%;
  padding: 5px;
  align-content: center;
  cursor: pointer;
}

.imageDeleteIcon {
  height: 15px;
  width: 15px;
  margin: 5px;
}

.noProductImageDiv {
  font-family: 'Poppins';
  font-size: 14px;
  margin: 10px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.714);
  cursor: default;
}

.uploadProductImagesHeader, .pricingBreakdownTitle, .deliveryMethodsTitle {
  font-family: 'Poppins';
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
  text-align: center;
  cursor: default;
  font-weight: bold;
}

.pricingBreakdownTitle, .deliveryMethodsTitle {
  margin: 10px 0;
}

.uploadProductImagesHeader {
  margin: 0 0 10px 0;
}

.pricingBreakdownTitle {
  margin: 10px 10px 5px  10px;
}

.shippingMethodDescription {
  font-family: 'Poppins';
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 20px;
  width: 90%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.7);
  cursor: default;
}



.bundlingDiv {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 98%;
  height: fit-content;
  border-radius: 20px;
  margin: 20px 0 20px 0;
  background: rgba(208, 215, 215, 0.4);
  border: solid rgba(255, 255, 255, 0.1) 2px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* .bundlingDivContainer {
  display: flex;
  flex-direction: row;
} */

.bundlingInfoIcon, .pricingBreakdownInfoIcon {
  width: 20px;
  height: 20px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}

.bundlingInfoIcon:hover, .pricingBreakdownInfoIcon:hover {
  filter: invert(1);
}

.bundlingDivHeader {
  font-family: 'Poppins';
  margin-top: 10px;
  font-weight: bold;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.7);
  cursor: default;
}

.bundlingDescription {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 10px;
  padding: 5px 10px;
  width: calc(100% - 20px);

  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  box-sizing: border-box;
  font-family: 'Poppins';
  cursor: default;
}

.bundlingQuestionText {
  font-size: 14px;
  font-weight: bold;
  margin: 5px 0;
  width: 150px;
}

.bundlingBtnContainer {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
}

.deliveryButtons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin: 10px 0;
  width: 95%;
}

.bundlingBtnYes, .bundlingBtnNo, .deliveryButton {
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 14px;
  background-color: black;
  color: white;
  padding: 5px 15px 5px 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.bundlingBtnYes {
  margin-right: 10px;
}

.selected, .deliveryButton:hover, .bundlingBtnYes:hover, .bundlingBtnNo:hover, .bundlingInfoBtn:hover {
  background-color: white;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.2) 0px 10px 10px -5px;
}

.bundlingInfoDiv {
  display: flex;
  flex-direction: column;
  position: absolute;
  margin: 5px auto;
  gap: 5px;
  padding: 10px 5px 0px 5px;

  left: 0;
  right: 0;
  height: fit-content;
  width: 80%;
  border: solid rgba(255, 255, 255, 0.2) 2px;
  border-radius: 20px;
  background: rgba(208, 215, 215, 0.7);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);

  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  z-index: 100;
  font-size: 14px;
  font-family: 'Poppins';
}

.bundlingInfoText {
  text-align: left;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  margin: 0 5px;
}

.bundlingInfoBtn {
  width: fit-content;
  background-color: black;
  font-family: 'Poppins';
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  color: white;
  border-radius: 10px;
  padding: 5px 20px;
  cursor: pointer;
  margin: 5px auto 10px auto;
}

.feesPopupBackground {
  visibility: hidden;
  display: flex;
  justify-content:center;
  align-items: center;
  position: fixed;

  height: 100%;
  width: 100%;
  top: 0px;
  filter: brightness(80%);
  z-index: 5;
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
}

.feesPopup {
  visibility: hidden;
  position: absolute;

  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;

  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: left;
  align-content: center;

  background: rgba(208, 215, 215, 0.6);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border: solid rgba(255, 255, 255, 0.1) 2px;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  border-radius: 20px;
  margin: 20px;
  z-index: 6;
  width: 300px;
}

.feesPopupBtnDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.sellerPriceDiv, .allFeesDiv, .listedPriceDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  margin-left: 15px;
}

.sellerPrice, .allFees, .listedPrice {
  width: 70%;
  margin: 10px 0px 0px 0px;
}

.feesPopupLine {
  margin-top: 10px;
  margin-bottom: 10px;
  position: left;
}

.gotItButton, .seeNewProductButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 14px;
  color: white;
  padding: 10px 15px;
  border-radius: 10px;
  margin: 0% 5% 0% 5%;
  cursor: pointer;
  width: 90%;
  height: 35px;
  border: none;
}

.gotItButton:hover, .seeNewProductButton:hover {
  filter: invert(1);
}

.scanningOverlay {
  position: absolute;
  top: 10px;
  left: 0px;
  width: calc(100% - 15px);
  height: calc(100% - 23px);
  background-color: rgba(0, 0, 0, 0.4); /* Semi-transparent overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none; /* Allows clicks to pass through */
  border-radius: 10px; /* Match image border radius if any */
}

.imageUploadPreviewDiv {
  position: relative;
  display: inline-block; /* or appropriate display */
}


.gotItButtonIcon, .seeNewProductIcon {
  width: 20px;
  height: 20px;
  filter: invert(1);
}