.vendorOpeningPopupBackground {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;

  width: 100%;
  height: 100%;

  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);
  z-index: 1002;
}

/* VendorCreateStorePopup.css */
.newStorePopup {
  position: fixed;
  bottom: 65px; /* As requested */
  left: 0;
  width: 100%;
  z-index: 1000;
  display: flex;
  justify-content: center;

}

.newStorePopupContent {
  background-color: rgb(182, 84, 84);
  border-radius: 20px;
  padding: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-family: 'Poppins';
  width: calc(100% - 20px);
  box-sizing: border-box;
}

.gettingStartedHeader {
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 20px 0; 
}

.newStorePopupText {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 5px;
  font-size: 14px;
}

.gettingStartedSection {
  padding: 10px;
  background-color: #e19a98;
  border-radius: 15px;
}

.vendorInfoBtn {
  justify-content: space-between;
  width: 130px;
  margin: 5px 5px 0 0;
}

.vendorInfoPlusBtn {
  margin: 5px 5px 0 5px;
}

.vendorInfoBtn, .vendorInfoPlusBtn {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  background: white;
  color: black;
  padding: 7px 10px;
  border: none;
  border-radius: 10px;
}

.vendorInfoBtnIcon {
  height: 20px;
  width: 20px;
  margin: 0 5px;
  filter: brightness(0)
}

.closeNewStorePopupBtn {
  width: 15px;
  height: 15px;
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
  right: 25px;
  top: 15px;
  position: absolute;
}


.vendorLoadingDiv {
  display: flex;
  align-items: center;
  justify-content: center;

  filter: brightness(0);
  margin: 40px auto;
  animation: spin 2s infinite linear;
}

.vendorLoadingIcon {
  width: 40px;
  height: 40px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


.vendorTimeoutLogin {
  position: fixed;
  display: flex;
  justify-content: center;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.4);
  -webkit-backdrop-filter: blur(30px);
  backdrop-filter: blur(30px);
  overflow: hidden;
  touch-action: none;
}

.vendorTimeoutLoginDiv {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
}

.vendorTimeoutLoginTextDiv {
  border-radius: 15px;
  font-family: 'Poppins';
  text-align: center;
  font-size: 14px;
  width: 100%;
  background-color: #C9DCB3;
  box-sizing: border-box;
  padding: 10px 15px;
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 15px;
}

@media only screen and (min-width: 450px) {
  .vendorTimeoutLoginTextDiv {
    width: 439px;
  }
}

.vendorLoginDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;
  position: fixed;
  z-index: 1002;

  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px);
}

.vendorBar {
  display: flex;
  flex-direction: row;
  position: fixed;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  top: 0;
  left: 0;
  
  border: solid rgba(255, 255, 255, 0.1) 2px;
  border-top: none;
  box-sizing: border-box;
  background: rgba(208, 215, 215, 0.6);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  z-index: 1000;

  border-radius: 0 0 20px 20px;
  padding: 10px 0;

  box-shadow: 
    rgba(0, 0, 0, 0.18) 10px 5px 15px -10px, 
    rgba(0, 0, 0, 0.05) 0px 5px 10px -10px, 
    rgba(0, 0, 0, 0.155) 0px 25px 40px -5px, 
    rgba(0, 0, 0, 0.01) 0px 35px 10px -10px, 
    rgba(0, 0, 0, 0.08) 0px 15px 15px -10px;

}

.addProductBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  max-width: 200px;
  height: 42px;
  border-radius: 10px;
  box-shadow: -2px -1px 2.5px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.6);
  box-sizing: border-box; 
  padding: 5px 10px 5px 15px;
  margin: 0 10px;
  cursor: pointer;
}

.addProductBtnText {
  display: flex;
  text-align: center;
  align-items: center;
  padding: 0px;
  margin: 0px 30px 0px 0px;
  height: 40px;
  color: white;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 14px;
}

.addProductBtnIcon {
  filter: invert(1);
  width: 20px;
  height: 20px;
}

.vendorBarIconContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 42px;
  width: fit-content;
  gap: 5px;
  margin: 0 10px;
}

.frameZIndexBtn {
  font-family: 'Poppins';
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
  cursor: pointer;
  font-weight: bold;
  font-size: 12px;
}

.stickerDimensions {
  display: flex;
  flex-direction: row;
  font-family: 'Poppins';
}

.vendorLoadingDiv {
  display: flex;
  align-items: center;
  justify-content: center;

  filter: brightness(0);
  margin: 80px auto;
  animation: spin2 2s infinite linear;
}

.vendorLoadingIcon {
  width: 40px;
  height: 40px;
}

@keyframes spin2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.img:hover::after {
  content: attr(title);
  position: absolute;
  font-family: 'Poppins';
  left: 0;
  top: 100%;
  background: #000;
  color: #fff;
  padding: 4px 8px;
  white-space: nowrap;
}

.deleteStickerDiv {
  position: fixed;
  border-radius: 10px;
  margin: 5px 5px 0 5px;
  border: solid rgba(255, 255, 255, 0.1) 2px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  background: rgba(208, 215, 215, 0.65);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  cursor: pointer;
  z-index: 2000;
}

.deleteStickerDiv:hover {
  filter: brightness(0.8);
}

.vendorStickerDeleteIcon {
  margin: 5px;
  width: 36px;
  height: 36px;
}

.websiteBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}

#changePageColour {
  width: 35px;
  height: 35px;
  cursor: pointer;
  margin: 3px auto
}

#wallet, .dashboardIcon, #share  {
  padding: 5px;
  cursor: pointer;
}

#wallet, .dashboardIcon  {
  width: 30px;
  height: 30px;
}

#share  {
  width: 28px;
  height: 28px;
}

#wallet:hover, .dashboardIcon:hover, 
.dashboardIcon.highlighted, #share:hover {
  border-radius: 7px;
}

#wallet:hover, .dashboardIcon:hover, .dashboardIcon.highlighted,
#share:hover, .frameZIndexBtn:hover {
  box-shadow: rgba(50, 50, 93, 0.1) 0px 15px 25px -12px inset, rgba(0, 0, 0, 0.2) 0px 18px 36px -18px inset, 
    rgba(0, 0, 0, 0.1) inset 0 2px 10px 0,   /* Top border */
    rgba(0, 0, 0, 0.1) inset 2px 0 10px 0,   /* Left border */
    rgba(0, 0, 0, 0.04) inset 0 -1px 0 0,  /* Bottom border */
    rgb(0, 0, 0, 0.04) inset -1px 0 0 0  /* Right border */ ;
}

.frameZIndexBtn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

#stickerDimension {
  background: #f5f5f5;
  width: 30px;
  margin-left: 5px;
  margin-right: 5px;
  height: 30px;
  border-radius: 5px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border: 0px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
}

.frameSelector, .stickerSelector {
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  bottom: 0;
  height: 60px;
  width: 60px;
  margin: 10px 15px;

  border-radius: 15px;
  z-index: 200;

  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
}

.stickerSelector {
  right: 0px;
  animation: flow 8s ease-in-out infinite;
  background: linear-gradient(-45deg, #7709f591, #79b4fc);
  background-size: 150%;
}

.frameSelector {
  left: 0px;
  background: rgba(208, 215, 215, 0.6);
}

@keyframes flow {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

.frameSelector:hover, .stickerSelector:hover {
  filter: brightness(80%);
}

.frameSelector:hover {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;
}

.stickerSelector:hover {
  box-shadow: rgba(10, 63, 124, 0.5) 0px 20px 25px -5px, rgba(10, 63, 124, 0.259) 0px 10px 10px -5px;
}

#frameIcon {
  width: 36px;
}

#stickerIcon {
  width: 40px;
}

.usersFramesDiv {
  position: relative;
  width: 100%;
  padding-bottom: 140px;
  padding-left: 0px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-top: 80px; /* Adjusted from top: 80px */
}


.usersDraggableFramesDiv {
  height: fit-content;
  width: fit-content;
  position: absolute;
  /* align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column; */
}

.popupBackground {
  display: flex;
  justify-content:center;
  align-items: center;
  position: absolute;

  height: 100%;
  width: 100%;
  z-index: 2;

  top: -18px;
  right: 0px;
}

.vendorInfoIcon {
  width: 25px;
  height: 25px;
  filter: opacity(70%);
}

.vendorInfoIconDiv {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;

  bottom: 80px;
  right: 15px;
  height: 30px;
  width: 30px;
  padding: 5px;
  
  background-color: #C9DCB3;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;
  cursor: pointer;
  z-index: 200;
}

.dashboardPopup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  height: fit-content;
  position: fixed;

  border-radius: 20px;
  font-family: 'Poppins';
  z-index: 900;

  left: 0;   
  right: 0;        
  margin: 0 auto;
  top: 70px;

  width: 350px;
  padding: 10px;

  border: solid rgba(255, 255, 255, 0.05) 2px;
  background: rgba(255, 255, 255, 0.5);

  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  cursor: pointer;
}

.dashboardPopupText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;

  font-size: 14px;

  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 10px;

  font-family: 'Poppins';
  box-sizing: border-box;
}

.dashboardPopupBtn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  background-color: black;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 14px;
  color: white;
  padding: 10px 15px;
  border-radius: 10px;
  margin: 10px 0 0 0;
  cursor: pointer;

  border: none;
}

.dashboardPopupBtn:hover {
  filter: invert(1);
}

.dashboardPopupBtnIcon {
  width: 20px;
  height: 20px;
  filter: invert(1);
}