html {
  scroll-snap-type: y mandatory;
}

.scoutCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  scroll-snap-type: y mandatory;
  scroll-snap-align: center;
  background: rgba(208, 215, 215, 0.5);
  height: calc(100vh - 190px);
  border-radius: 27px;
  border: solid rgba(255, 255, 255, 0.1) 3px;
  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.65) 0px 55px 75px -12px, 
    rgba(0, 0, 0, 0.7) 0px 15px 30px -15px,
    rgba(255, 255, 255, 0.1) 0px -5px 25px inset;  

  overflow: auto;
}

.scoutCard.ownStore {
  border: 3px solid rgba(255, 56, 56, 0.2); /* Red border for own store products */
  background: rgba(237, 91, 54, 0.6);
}

.scoutIcons {
  height: 22px
}
@media (max-width: 669px) {
  .iconsDivfalse {
    top: 28px;
  }
  .infoDivWrapper, .infoDivWrappercollapsed {
    width: 100%
  }
  .productNamefalse, .productNametrue {
    margin: 10px 0 0px 10px;
    width: 100%;
  }
  .productStoreNamefalse, .productStoreName {
    width: 260px;
  }
  .productDescriptionfalse, .productDescription, .productDescriptiontrue  {
    width: calc(100% - 10px)
  }
  .productNametrue, .productStoreNametrue {
    width: 280px;
  }  
}

@media (min-width: 670px) {
  .scoutCard, .scoutCardContainer {
    display: flex;
    flex-direction: row
  }
  .infoDivWrapper, .infoDivWrapperCollapsed {
    width: 49%;
    right: 0px;
  }
  .infoDivWrapper {
    margin: 1px;
  }
  .productNamefalse, .productNametrue {
    margin: 15px 0 0px 10px;
  }
  .iconsDivfalse {
    top: 18px;
  }
  .textDiv {
    width: calc(100% - 45px);
  }
}

@media (max-width: 999px) {
  .scoutCard {
    width: 90vw;
  }
}

@media (min-width: 1000px) {
  .scoutCard {
    width: 900px;
  }
}

@media (max-height: 795px) {
  .productDescriptionfalse, .productDescription {
    -webkit-line-clamp: 2
  }
}

@media (min-height: 796px) {
  .productDescriptionfalse, .productDescription {
    -webkit-line-clamp: 3
  }
}

.scoutCardContainer {
  position: relative;
  height: calc(100% - 8px);
  width: calc(100% - 8px);
  margin: 4px;
}

.price {
  position: absolute;
  padding: 2px 13.26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  border-radius: 10px 15px 4px 10px;
  right: 5px;
  top: 5px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 2px 2px;
  cursor: default;
}

.buyButton, .buyButtonfalse {
  perspective: 1000px;
  position: absolute;
  width: fit-content;
  display: inline-flex;
  justify-content: center;overflow: hidden;
  transition: all 0.3s ease;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.95);
  color: black;
  border-radius: 10px 4px 15px 10px;
  right: 5px; 
  padding: 0 10px;
  bottom: 5px;
  font-weight: bold;
  font-size: 19px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
  cursor: pointer;
  max-width: 73px;
  transition: width 0.3s ease, transform 0.3s ease;
}

.buyButton span {
  display: block;
  transform-origin: top center;
  white-space: nowrap;
}

.buyButtontrue {
  position: fixed;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.95);
  color: black;
  border-radius: 10px 2px 15px 10px;
  right: 10px;
  padding: 0 10px;
  bottom: 10px;
  font-weight: bold;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;
  cursor: pointer;
  z-index: 3;
}

.buyButtonfalse:hover {
  filter: invert(1);
}

.buyButtontrue:hover {
  filter: invert(1);
}

/* .productTagstrue {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: bottom;
  align-items: bottom;
  position: bottom;
  margin-left: 10px;
  margin-top: 4px;
  width: 250px;
} */

/* .categoryTag {
  font-family: 'Poppins';
  font-size: 14px;
  font-weight: bold;
  border-radius: 8px;
  height: 25px;
  border-style: none;
  animation: flow 5s ease-in-out infinite;
  background: linear-gradient(-40deg, #2ebf91, #8360c3);
  background-size: 200%;
  color: rgba(0, 0, 0, 0.75);
  cursor: pointer;
}

.categoryTag:hover {
  filter: invert(1);
}

@keyframes flow {
  0% {
    background-position: 0 50%;
  }

  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
} */

/* .productTagsfalse {
  display: none;
} */

.ethicalStickerImg {
  position: absolute;
  height: 120px;
  width: 120px;
  left: 0px;
  top: 10px;
  cursor: pointer;
}

.infoDivWrapperCollapsed, .infoDivWrapper {
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  height: calc(22% - 5px);
}

.infoDivWrapperExpanded {
  position: absolute;
  box-sizing: border-box;
  bottom: 0;
  width: 100%;
  height: calc(76% - 8px);
}

.infoDivCollapsed {
  background: linear-gradient(180deg, rgba(121, 97, 97, 0.3) 0%, rgba(255, 252, 252, 0.18) 77.43%);
  box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.085) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.1) 0px 8px 8px, rgba(0, 0, 0, 0.18) 0px 16px 16px 8px;
  display: flex;
  position: absolute;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  border-radius: 20px;
  height: 100%;
  transform-origin: bottom;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent; /* This is for iOS and some other mobile browsers */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.infoDiv {
  background: linear-gradient(180deg, rgba(121, 97, 97, 0.3) 0%, rgba(255, 252, 252, 0.4) 77.43%);
  box-shadow: -1px -2px 2px rgba(255, 255, 255, 0.6), rgba(0, 0, 0, 0.1) 0px 1px 1px, rgba(0, 0, 0, 0.085) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.1) 0px 8px 8px, rgba(0, 0, 0, 0.2) 0px 16px 16px 8px;
  display: flex;
  position: absolute;
  flex-direction: row;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
  backdrop-filter: blur(14px);
  -webkit-tap-highlight-color: transparent; /* This is for iOS and some other mobile browsers */
}

.infoDivExpanded {
  display: flex;
  position: absolute;
  flex-direction: row;
  width: 100%;
  border-radius: 20px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 252, 252, 0.28) 77.43%);
  box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.9), rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.085) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.1) 0px 8px 8px, rgba(0, 0, 0, 0.18) 0px 16px 16px 8px;
  height: 100%;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent; /* This is for iOS and some other mobile browsers */
}

.textDiv {
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
  width: calc(100% - 40px);
  
  word-wrap: break-word; /* Allows long words to wrap */
  overflow-wrap: break-word; /* Alternative property for wider browser support */
  white-space: normal; /* Ensures text breaks into new lines */
}


.textDivExpanded {
  display: flex;
  position: bottom;
  justify-content: left;
  align-items: left;
  flex-direction: column;
  overflow: scroll;
  width: calc(100% - 10px);
  height: calc(100% - 40px)
}

.productNamefalse, .productNametrue {
  position: top;
  line-height: 1.3;
  width: calc(100% - 20px);
  font-size: 18px;
  font-weight: bold;
  word-wrap: break-word; /* Older syntax */
  overflow-wrap: break-word; /* Newer syntax, works in most browsers */
}

.productNamefalse {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* limits the text to one line */
  -webkit-box-orient: vertical;
}


.productStoreNamefalse, .productStoreName {
  margin: 2px 0 8px 10px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.productDescriptionfalse, .productDescription {
  display: -webkit-box;           /* Establish a flex container */
  -webkit-box-orient: vertical;   /* Set the box orientation to vertical */
  text-overflow: ellipsis;        /* Add an ellipsis (...) at the end */
  margin: 0 0 0 10px;
  font-size: 14px;
  opacity: 85%;
  overflow: hidden;
}

.productNametrue {
  margin: 7px 0 2px 10px;
}

.productStoreNametrue {
  margin: 2px 0 8px 10px;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.productDescriptiontrue {
  margin: 0 0 0 10px;
  font-size: 14px;
  height: calc(100% - 20px);
  width: calc(100% - 10px);
  overflow-x: auto;
  overflow-y: scroll;
  z-index: 109;
}

.iconsDiv {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 22px;
  height: auto;
  right: 10px;
  bottom: 10px;
}

.iconsDivfalse {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 22px;
  min-height: 55px;
  max-height: 90px;
  animation: moveIconsUp 1s ease forwards;
  right: 10px;
  gap: 5px
}

.iconsDivtrue {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 22px;
  top: 50px;
  right: 8px;
  height: 55px;
  gap: 5px;
}

/* 
@keyframes moveIconsDown {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(20%, -405%);
  }
}

@keyframes moveIconsUp {
  0% {
    transform: translate(20%, 50%);
  }
  100% {
    transform: translate(0, 0);
  }
} */

#heartIconfalse, #redHeartIconfalse {
  width: 22px;
  height: 22px;
  cursor: pointer;
}

#redHeartIconfalse, #redHeartIcontrue {
  filter: drop-shadow(1px 3px 5px #db2e3c80);
  pointer-events: none;
}

#heartIcontrue, #redHeartIcontrue {
  height: 22px;
  cursor: pointer;
  margin-bottom: 2px;
  width: 22px;
}

#bundlingIconfalse, #bundlingIcontrue, #shareIcon {
  cursor: pointer;
}
/* ScoutCard.css */

/* Image Container */
.imageContainer {
  position: relative;
  display: inline-block;
  overflow: hidden;
}