.buyerInfoPopupBlur {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex-direction: column;
  top: 0px;
  left: 0px;

  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(20px);
  background-color: rgba(0, 0, 0, 0.5);

  z-index: 1000;
}

.buyerInfoPopupDiv {

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  width: calc(100% - 20px);
  margin: 10px;
  left: 0;

  z-index: 1001;
  font-family: 'Poppins';

}

.buyerDetailsContainer, .buyerShippingDetailsContainer {
  padding: 10px;
  background: rgba(255, 255, 255, 0.55);
  border: solid rgba(255, 255, 255, 0.2) 2px;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

  backdrop-filter: blur(14px);
  -webkit-backdrop-filter: blur(14px);
}


#buyerInfoFirstNameInput, 
#buyerInfoSurnameInput, 
#buyerInfoCellphoneInput, 
#buyerInfoAddressInput,
#buyerInfoEmailInput,
#pudoLockerAddressInput {
  box-sizing: border-box;
  height: 30px;
  margin: 5px 0;
  border-radius: 10px;
  border: none;
  background-color: #f5f5f5;
  padding-left: 5px;
  font-family: 'Poppins';
  font-size: 13px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
}

#buyerInfoAddressInput {
  width: 100%;
}

#buyerInfoFirstNameInput, 
#buyerInfoSurnameInput, 
#buyerInfoCellphoneInput, 
#buyerInfoEmailInput {
  width: 100%;
}

#pudoLockerAddressInput {
  width: calc(100% - 130px)
}

#buyerInfoFirstNameInput:focus, 
#buyerInfoSurnameInput:focus,
#buyerInfoCellphoneInput:focus,
#buyerInfoAddressInput:focus,
#buyerInfoEmailInput:focus {
  outline: none;
  box-shadow: none;
}

.buyerInfoAddressDiv {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

/* Address Autocomplete */
.addressAutocompleteDiv {
  position: relative;
  width: 85%;
}

.buyerInfoPopupAddressSuggestionsList {
  position: fixed;
  left: 10px;
  right: 0;
  width: calc(100% - 32px);
  background: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  border-top: none;
  height: fit-content;
  overflow-y: auto;
  z-index: 900; /* Ensure it appears above other elements */
  list-style: none;
  margin: 0 0 5px 0;
  padding: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.suggestionItem {
  padding: 10px;
  cursor: pointer;
}

.suggestionItem:hover {
  background-color: #f0f0f0;
}

/* Informational Message */
.infoMessage {
  width: 85%;
  margin: 5px;
  padding: 10px;
  background-color: #e7f3fe;
  border-left: 6px solid #2196F3;
  color: #0b3d91;
  font-family: 'Poppins';
  border-radius: 10px;
}

.shippingEstimatedTime {
  background-color: rgba(0, 0, 0, 0.25);
  padding: 10px;
  margin-top: 10px;
  border-radius: 15px;
  font-size: 13px;
}

.buyerInfoCourierMethodsInset {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.shippingMethodContainer {
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.25);
  padding: 10px;
}

.locateAddressBtn {
  width: 250px
}

.findLockerButton, .findStoreButton {
  width: 120px;
}

.locateAddressBtn, .findLockerButton, .findStoreButton {
  background-color: black;
  color: white;
  height: 30px;
  margin: 5px 0;
  border-radius: 10px;

  font-size: 13px;
  font-family: 'Poppins';
  font-weight: 500;
  cursor: pointer;
  border: none;
}

.cartSubmitButton:hover {
  filter: invert(1);
}

/* Submission Status Messages */
.submitStatus {
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  font-family: 'Poppins';
  text-align: center;
}

.submitStatus.success {
  background-color: #d4edda;
  color: #155724;
}

.submitStatus.error {
  background-color: #f8d7da;
  color: #721c24;
}

.conditionalDiv.pudoDiv, .aramexMessage {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border-radius: 10px;
  padding: 10px;
  background-color: rgba(255, 162, 0, 0.568);
}

.aramexMessage {
  font-size: 13px;
  margin-bottom: 10px;
}

.pudoInputContainer {
  display: flex;
  justify-content: space-between;
}

.pudoLockerAddressHeader {
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 13px;
}

.courierLocationExistingValue {
  width: calc(100% - 130px);
  font-size: 13px;
}

.paymentRecapBtn {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #C9DCB3;  
  color: rgba(0, 0, 0, 0.7);
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1002; /* Above buyerInfoPopupBlur */
  transition: background-color 0.3s ease;
}

.paymentRecapBtn:hover {
  background-color: #e68900; /* Darker shade on hover */
}

/* Payment Recap Container */
.paymentRecap {
  width: 300px; /* Adjust width as needed */
  background-color: rgba(255, 255, 255, 0.55);
  border: solid rgba(255, 255, 255, 0.2) 2px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

  padding: 10px;
  backdrop-filter: blur(14px);
  box-sizing: border-box;
}

/* Payment Recap Header */
.paymentRecapHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* Close Recap Button */
.closeRecapBtn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.closeIcon {
  width: 20px;
  height: 20px;
}

/* Payment Recap Content */
.paymentRecapContent {
  margin-top: 10px;
  font-family: 'Poppins';
  font-size: 13px;
}

.paymentRecapHeaderText {
  font-weight: bold;
  font-family: 'Poppins';
  font-size: 22px;
}

.paymentRecapRow {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
  font-weight: bold;
}

.paymentRecapTotal {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  margin: 0 0 10px 0;
}

/* Ensure buyerInfoPopupBlur does not cover the Payment Recap */
.buyerInfoPopupBlur {
  z-index: 1001; /* Below paymentRecap and paymentRecapBtn */
}

/* Optional: Add transition for smoother animation when closing the recap */
.paymentRecap {
  overflow: hidden;
}

/* Adjust BuyerInfoPopupDiv margin when Payment Recap is visible */
.buyerInfoPopupDiv.authentication {
  transition: margin-top 0.3s ease;
}

/* Optional: Responsive adjustments */
@media (max-width: 600px) {
  .paymentRecapBtn {
    top: 10px;
    right: 10px;
    padding: 8px 16px;
    font-size: 14px;
  }

  .paymentRecap {
    width: calc(100% - 20px);
    right: 0;
    top: 0px;
    margin: 10px
  }
}

.buyerInfoIcon {
  position: absolute; 
  top: 10px; 
  right: 10px; 
  cursor: pointer;
  width: 20px;
  height: 20px
}

.infoPopupSection {
  font-size: 14px;
}

.buyerExtraInfoPopup {
  right: 0;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}

.buyerInfoNextButton, .buyerInfoBackButton, .cartSubmitButton {
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'Poppins';
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  margin-top: 10px;
}

.navigationButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}