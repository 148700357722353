/* src/admin/components/AdminOrderHistory.css */

.admin-order-history {
  padding: 10px;
  font-family: 'Poppins';
}

.store-section {
  margin-bottom: 30px;
  border: 1px solid #ccc;
  background-color: white;
  padding: 15px;
  border-radius: 8px;
}

.orders-list {
  list-style-type: none;
  padding: 0;
}

.order-item {
  margin-bottom: 15px;
}

.order-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: #f9f9f9;
  padding: 10px;
  border-radius: 5px;
}

.order-summary:hover {
  background-color: #e9e9e9;
}

.toggle-button, .view-details-button {
  padding: 5px 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
}

.toggle-button:hover, .view-details-button:hover {
  background-color: #0056b3;
}

.order-details {
  margin-top: 10px;
  padding: 10px;
  border-left: 3px solid #007bff;
  background-color: #f1f1f1;
}

.products-list {
  list-style-type: none;
  padding: 0;
}

.product-item {
  margin-bottom: 10px;
}

.product-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-summary span {
  flex: 1;
}
