.marketPageBody {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh; 
  margin: 0;
  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box; 
  overflow-x: hidden;
}

.marketTopSectionContainer {
  position: absolute;
  display: grid;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  top: 0px;
  left: 0px;
  padding: 10px;
  width: 100%;
  height: 200px;
}

.marketGradientCanvas {
  position: absolute;
  display: block;
  top: -10px;
  left: -20px;
  height: 230px;
  width: 150%;
  margin: 0;
  filter: blur(10px);
  background: #EFC6A9;
  z-index: 0;
}

.marketDottedTexture {
  position: absolute;
  object-fit: cover;
  top: 0;
  left: -30px;
  height: 220px;
  width: calc(100% + 30px);
  opacity: 70%;
  mix-blend-mode: overlay; /* Experiment with different blend modes like overlay, soft-light, etc. */
  filter: contrast(100%) brightness(85%) invert(100%);
  z-index: 4;
}

/* .marketClearTextIcon:hover {
  background-color: rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
} */

.marketFilterDiv {
  position: relative;
  margin-top: 18px;
  height: fit-content;
  z-index: 5;
  transition: height 0.3s ease;
}

.marketFilterCategoryDiv {
  max-height: 90px;
}

.marketFilterCategoryDiv, .marketFilterCategoryDivExpanded {
  /* height: fit-content; */
  border-radius: 20px 20px 0px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px 0px 0px 10px;
  overflow-y: hidden;
  z-index: 3;
  background: rgba(208, 215, 215, 0.5);
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.5), rgba(0, 0, 0, 0.25) 0px 30px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
}

.marketSearchUnavailable {
  text-align: center;
  font-family: 'Poppins';
  border-radius: 20px;
  height: fit-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 10px;
  overflow-y: hidden;
  z-index: 3;
  background: rgba(255, 255, 255, 0.5);
  margin: 10px;
  /* width: 100vw; */
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 30px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
  box-sizing: border-box;
}

.marketSearchUnavailableText {
  margin: 5px 0;
}

.marketCategoryTag {
  border-radius: 25px;
  margin: 3px 8px 8px 0;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;

  color: black;
  padding: 3px 8px 3px 8px;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  font-family: 'Poppins';
}

.marketCategoryTag.actively {
  background-color: #ED8951;
  box-shadow: rgba(255, 112, 3, 0.2) 0px 10px 25px -5px, rgba(255, 112, 3, 0.259) 0px 10px 10px -5px;
}

.marketCategoryTagText {
  margin: 4px;
}

.marketDropdownIcon {
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 7.5px;

  cursor: pointer;
  transform: rotate(180deg);
}

.marketDropdownIcon2 {
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 7.5px;
  cursor: pointer;
}

.marketExpandFiltersBtn {
  float: right;
  border-radius: 0px 0px 10px 10px;
  width: fit-content;
  background-color: #ED8951;
  padding: 3px 15px 3px 15px;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  font-family: 'Poppins';
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  z-index: 3;
}

.marketExpandFiltersBtn:hover {
  background-color: #ed722b;
  box-shadow: rgba(255, 112, 3, 0.2) 0px 10px 25px -5px, rgba(255, 112, 3, 0.259) 0px 10px 10px -5px;
}

.marketBottomSectionContainer {
  position: absolute;
  top: 200px;
  width: 100vw;
  height: auto;
  left: 0px;
}

.marketStoresSectionContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 210px;
}

.marketStoresSection {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: fit-content;
  z-index: 3;
}

/* .marketStoresSection1 {
  display: flex;
  flex-direction: column;
  
  margin: 165px 0 0 -10px;
  z-index: 3;
} */

.marketStoresSectionHeader {
  font-family: 'Poppins';
  font-weight: 900;
  font-size: 28px;
  color: #B28666;
  margin: 10px 15px 0px 15px;
  cursor: default;
}

.marketProductContainer {
  width: 100%;
}

.marketStoreDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 160px;
  margin: 0px;
  overflow-x: scroll;
  border-radius: 0 0 20px 20px;
}

.marketSortSelect {
  position: absolute;
  z-index: 11;
}

.sortedTagsDiv {
  display: flex;
  position: relative;
  height: fit-content;
  flex-direction: row;
  overflow-x: scroll;
  margin: 0;
  width: 100vw;
  overflow-y: hidden;
  z-index: 2;
}

/* make it brownish red */

.sortedTag {
  width: fit-content;
  max-width: fit-content;
  white-space: nowrap;

  font-family: 'Poppins';
  font-weight: bold;
  margin: 3px 0px 0px 10px;
  border-radius: 25px;
  color: rgba(255, 255, 255, 0.8);
  background-color: #B74529;
  font-size: 13px;
  cursor: pointer;
}

.sortedTag {
  padding: 5px 10px;
}

.sortedTagsBack {
  white-space: nowrap;
  border-radius: 25px;
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.8);
  background-color: #B28666;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sortedTagsBackIcon {
  width: 25px;
  height: 25px;
  padding: 5px 15px;
  filter: invert(24%) sepia(5%) saturate(4237%) hue-rotate(341deg) brightness(99%) contrast(86%);
}

.sortedTagsBack:hover {
  background-color: #5F4330;
}

.sortedTagsBackIcon:hover {
  filter: invert(54%) sepia(12%) saturate(1224%) hue-rotate(343deg) brightness(101%) contrast(83%);
}

.marketProductsSection {
  align-self: flex-start;
  column-count: 2;
  -moz-column-count: 2; 
  -webkit-column-count: 2;
  column-gap: 0px;
  /* background: #f8dfc7; */

  width: 100vw;
  z-index: 3;
  left: 0;
  margin-top: 10px;
  padding: 10px 0px 70px 10px;
  box-sizing: border-box; 
  overflow-y: scroll;
}

@media only screen and (min-width: 600px) {
  .marketProductsSection {
    column-count: 3;
    -moz-column-count: 3; 
    -webkit-column-count: 3;
    column-width: 33%;
  }
}

@media only screen and (min-width: 800px) {
  .marketProductsSection {
    column-count: 4;
    -moz-column-count: 4; 
    -webkit-column-count: 4;
    column-width: 33%;
  }
}

@media only screen and (min-width: 1000px) {
  .marketProductsSection {
    column-count: 4;
    -moz-column-count: 4; 
    -webkit-column-count: 4;
    column-width: 19%;
  }
}

.noProductsForCategoryDiv, .noStoresForCategoryDiv {
  font-family: 'Poppins';
  margin: 40px auto;
  width: 100%;
  text-align: center;
  cursor: default;
}

.marketLoadingDiv {
  display: flex;
  align-items: center;
  justify-content: center;

  filter: brightness(0);
  margin: 40px auto;
  animation: spin 2s infinite linear;
}

.marketLoadingIcon {
  width: 40px;
  height: 40px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadMoreDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 70px;
}

.loadMoreBtn {
  border-radius: 25px;
  margin: 3px 8px 8px 0;
  background-color: black;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;

  color: white;
  padding: 3px 12px 3px 12px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  font-family: 'Poppins';
  width: fit-content;
}

.marketLoginDiv {
  width: fit-content;
  height: fit-content;
  margin-top: 10px;
  margin-left: 10px;
}

.marketLogin { 
  position: fixed;
  display: flex;
  justify-content: center;

  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1002;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
}

.marketLoginTextDiv {
  width: 97%;
  border-radius: 15px;
  font-family: 'Poppins';
  text-align: center;
  font-size: 14px;
  background-color: #C9DCB3;
  box-sizing: border-box;
  padding: 10px;
  margin-top: 0px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 15px;
}

@media only screen and (min-width: 450px) {
  .marketLoginTextDiv {
    width: 439px;
  }
}

.marketCategoryCarousel {
  height: 200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  position: relative;
  z-index: 10;
}

.carouselDiv1, .carouselDiv2, .carouselDiv3 {
  height: inherit;
}

.carouselDiv2, .carouselDiv3 {
  border-radius: 15px;
  width: 12%;
}

.carouselDiv1 {
  width: 70%;
  border-radius: 15px;
  background-color: #D55A44;
}

.carouselDiv2 {
  background-color: #A1674F;
}

.carouselDiv3 {
  background-color: #6D735A;
}

