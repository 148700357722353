.uploadLogoMediaDiv {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  margin-bottom: 10px;
  padding: 10px;

  z-index: 4;
  height: fit-content;
  background: rgba(208, 215, 215, 0.4);
  border: solid rgba(255, 255, 255, 0.1) 2px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  box-sizing: border-box;
}

.logoSelectionDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;

  font-family: 'Poppins';
  box-sizing: border-box;
}

.logoAvailableBtn {
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 14px;
  background-color: black;
  color: white;
  padding: 5px 15px 5px 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer; 
}

.logoAvailableBtn.selected {
  background-color: white; 
  color: black; 
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.2) 0px 10px 10px -5px;
}

.logoUploadPreviewDiv {
  display: flex;
  height: fit-content;
  margin: 0;
}

.logoUploadPreview {
  height: 200px;
  width: 150px;
  position: left;
  object-fit: cover;
  border-radius: 10px;
  margin: 10px 15px 0 0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;

  z-index: 1;
}

.logoInfoIcon {
  width: 20px;
  height: 20px;
  top: 10px;
  right: 10px;
  cursor: pointer;
  position: absolute;
}

.logoInfoPopup {
  display: flex;
  margin: 5px auto;
  top: 5px;
  position: absolute;
  right: 40px;
  justify-content: center;
  background: rgba(208, 215, 215, 0.7);
  border: solid rgba(255, 255, 255, 0.2) 2px;

  backdrop-filter: blur(12px);
  font-family: 'Poppins';
  font-size: 14px;
  border-radius: 20px;
  padding: 10px 20px;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.logoInfoPopupContent {
  width: 240px;
}

.logoInfoBtnIcon {
  height: 20px;
  width: 20px;
  margin-right: 10px;
  filter: brightness(0)
}

.logoInfoIcon:hover {
  filter: invert(1)
}

.logoInfoBtn {
  display: inline-flex;
  flex-direction: row;
  width: fit-content;
  align-items: center;
  justify-content: center;
  background: white;
  color: black;
  padding: 5px 15px;
  border: none;
  border-radius: 10px;
  margin: 5px 5px 5px 0;
}

.defaultLogoMessage {
  font-family: 'Poppins';
  font-size: 14px;
  margin: 10px 5px 5px 5px;
}