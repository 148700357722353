.learnMorePage {
  position: fixed;
  font-family: 'Poppins';
  top: 0;
  background-color: #EFC6A9;
  width: 100vw;
  height: 100vh;
}

.learnMoreHeader {
  display: flex;
  flex-direction: column;
  background-color: #585123;
  border-radius: 0 0 15px 15px;
  height: 280px;
  width: 100%;
}

.learnMoreTopSection {
  display: flex;
  flex-direction: row;
  margin: 10px;
  gap: 10px;
}

.learnMoreBackBtn {
  background-color: #585123;
  width: 65px;
  height: 45px;
  border-radius: 15px; 
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
}

.learnMoreBackArrowIcon {
  width: 35px;
  height: 35px;
  padding: 5px 15px;
  filter: opacity(0.6)
}

.learnMoreTopSectionHeader {
  color: #BFB25F;
  font-weight: bold;
  background-color: #585123;
  border-radius: 15px;;
  height: 45px;
  width: calc(100% - 30px);
  font-size: 32px;
  padding-left: 15px;
}

.learnMoreInfoSection1 {
  margin: 20px 10px;
  background-color: #CAA673;

  font-weight: bold;
  font-size: 14px;
  border-radius: 15px;

  padding: 10px 15px;
  color: #585123;
}

.learnMoreHeaderSec1, .learnMoreHeaderSec2 {
  border-radius: 15px;
  margin: 10px;
}

.learnMoreHeaderSec1 {
  background-color: #BFB25F;
  height: 215px;
}

.learnMoreHeaderSec2 {
  background-color: #C24F10;
  padding: 10px;

  font-weight: bold;
  color: #CAA673;
}