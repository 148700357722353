.loginHeader, .createAccountHeader, .googleAuthHeader, .passwordResetHeader {
  font-family: 'Poppins';
  font-size: 20px;
  margin-left: 5px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  cursor: default;
}


.authentication {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 100%;
  padding: 10px;

  border: solid rgba(255, 255, 255, 0.05) 2px; 
  border-radius: 30px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 15px;
  box-sizing: border-box;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  z-index: 20;
}

@media only screen and (max-width: 450px) {
  .userLoggedIn, .bootstraptAuth, .GoogleAuth, .adminLoggedIn {
    width: inherit;
  }
}

@media only screen and (min-width: 450px) {
  .authentication {
    width: 437px;
  }
  .userLoggedIn, .bootstraptAuth, .GoogleAuth, .adminLoggedIn {
    width: 413px;
  }
}

.bootstraptAuth {
  display: flex;
  flex-direction: row;
  align-items: top;
  align-content: center;
  justify-content: space-between;
  box-sizing: border-box;
  z-index: 20;
}

#loginForm, #createAccountForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  background-color: #F1A16D;
  width: 48%;
  height: 250px;
  box-shadow: 
    -2px -2px 2px rgba(255, 255, 255, 0.25), 
    2px 2px 4px rgba(0, 0, 0, 0.45);
}

.bootstraptPasswordDiv, .loginSectionPasswordDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.bootstraptAuthShowPasswordIcon, .loginSectionShowPasswordIcon {
  width: 23px;
  height: 23px;
  margin-right: 6px;
  float: right;
  cursor: pointer
}

#firstNameInput, #surnameInput, #emailInput, #emailInput2, .bootstraptPasswordDiv, .loginSectionPasswordDiv {
  box-sizing: border-box;
  height: 30px;
  width: 85%;
  margin: 5px;
  border-radius: 10px;
  border: none;
  background-color: white;
}

.forgotPasswordContainer {
  width: 85%;
  margin: 5px;
  box-sizing: border-box;
}

.forgotPasswordBtn {
  font-family: 'Poppins';
  height: 30px;
  text-align: left;
  padding-left: 15px;
  border: none;
  width: 100%;
  border-radius: 10px;
  background-color: #90604084;
  cursor: pointer;
  -webkit-appearance: none; 
}

.forgotPasswordBtn:hover {
  background-color: #906040; 
}

#firstNameInput, #surnameInput, #emailInput, #passwordInput, #emailInput2, #passwordInput2 {
  margin: 5px;
  font-family: 'Poppins';
}

#firstNameInput, #surnameInput, #emailInput, #emailInput2 {
  padding-left: 5px;
}

#passwordInput, #passwordInput2 {
  width: calc(100% - 40px);
  border: none;
}

#loginSubmitBtn, #createAccountSubmitBtn, .logoutBtn, .adminBtn {
  background-color: black;
  color: white;
  width: 85%;
  height: 30px;
  margin: 10px 0;
  border-radius: 10px;
  font-family: 'Poppins';
  font-weight: 500;
  cursor: pointer;
  border: none;
}

#loginSubmitBtn {
  margin-top: 45px;
}

.GoogleWarning {
  border-radius: 20px;
}

.GoogleAuth {
  border-radius: 20px;
}

.GoogleAuth, .GoogleWarning {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  height: fit-content;
  background: #F67C3A;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
}

.googleLogo {
  width: 20px;
  margin-right: 10px;
}

.passwordResetOverlay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  height: fit-content;
  border-radius: 20px;
  color: white;
  background: black;
  font-family: 'Poppins';
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
}

#forgottenPasswordEmailInput {
  border-radius: 10px;
  border: none;
  margin: 5px;
  font-family: 'Poppins';
  padding-left: 5px;
  box-sizing: border-box;
  height: 30px;
  width: calc(100% - 180px);
  margin: 5px;
  border-radius: 10px;
  border: none;
  background-color: #906040;
  color: white;
}

#forgottenPasswordEmailInput::placeholder {
  color: white; /* Placeholder text color */
  opacity: 1; /* Optional: ensures full color visibility */
}

.passwordResetPopup {
  width: 100%;
  box-sizing: border-box;
}

.passwordResetButtons {
  display: flex;
  flex-direction: row;
  width: fit-content
}

.passwordResetContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 10px;
}

.passwordResetBtn {
  font-family: 'Poppins';
  border: none;
  background-color: #906040;
  color: white;
  margin: 5px 0;
  border-radius: 10px;
  padding: 0 10px;
  height: 30px
}

.passwordResetCancelBtn {
  width: 20px;
  height: 20px;
  margin: 5px;
  padding: 5px;
  cursor: pointer;
  filter: invert(51%) sepia(20%) saturate(4794%) hue-rotate(325deg) brightness(98%) contrast(111%);
}

.googleAuthHeader, .passwordResetHeader {
  margin-top: 10px;
}

.GoogleAuthDescription {
  justify-content: center;
  text-align: center;
  align-items: center;

  font-family: 'Poppins';
  font-size: 14px;
  width: 85%;
  margin-top: 0px;
  cursor: default;
}

.GoogleWarningText1, .GoogleWarningText2 {
  justify-content: center;
  text-align: center;
  align-items: center;

  font-family: 'Poppins';
  font-size: 14px;
  width: 85%;
  
  cursor: default;
}

.GoogleWarningText1 {
  margin-top: 15px;
  margin-bottom: 0px;
}

.GoogleWarningText2 {
  margin-top: 15px;
}

.GoogleLoginBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  width: 92.8%;
  height: 40px;
  margin: 15px 5px;
  border-radius: 10px;
  font-family: 'Poppins';
  font-weight: 500;
  cursor: pointer;
  border: none;
}

.GoogleLoginBtn:focus {
  outline: none
}

.GoogleLoginBtn:hover {
  background-color: white;
  color: black;
  border: none;
  font-weight: bold;
}

#loginSubmitBtn:hover, #createAccountSubmitBtn:hover, .logoutBtn:hover, .adminBtn:hover {
  filter: invert(1);
}

#loginWarning {
  justify-content: center;
  position: absolute;
  top: 195px;
  align-items: center;
  width: fit-content;
  color: #FF5757;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 12px;
  z-index: 100;
}

.userLoggedIn {
  
}

.userLoggedIn, .adminLoggedIn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  background-color: #F67C3A;
  font-family: 'Poppins';
  font-size: 14px;

  border-radius: 20px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  z-index: 20;
}

.instagramWarningOverlay {
  background-color: #F67C3A;
  font-family: 'Poppins';
  font-size: 14px;
  padding: 0 10px;
  border-radius: 20px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  z-index: 20;
  margin-bottom: 10px;
}

.adminLoggedIn {
  margin-top: 10px;
  /* padding: 10px 0; */
}

.logoutDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logoutBtn {
  margin-bottom: 10px;
}

.warningLoggedInText {
  font-weight: bold;
}

/* Remove focus outlines and box-shadow from all input fields */
#firstNameInput:focus, 
#surnameInput:focus,
#emailInput:focus,
#emailInput2:focus,
#forgottenPasswordEmailInput:focus,
#passwordInput:focus,
#passwordInput2:focus {
  outline: none;
  box-shadow: none;
}

/* Ensure input fields do not have default background images or icons */
#firstNameInput, 
#surnameInput,
#emailInput,
#emailInput2,
#forgottenPasswordEmailInput,
#passwordInput,
#passwordInput2 {
  background-image: none;
  background-repeat: no-repeat;
  background-position: right center;
}

/* Optionally, prevent autofill styles that might add unwanted styling */
input:-webkit-autofill {
  box-shadow: 0 0 0 30px white inset !important;
}

/* Hide the default password toggle icon in Chrome, Safari, Edge */
input[type="password"]::-ms-reveal, /* IE 10+ */
input[type="password"]::-ms-clear,  /* IE 10+ */
input[type="password"]::-webkit-credentials-auto-fill-button, /* Safari */
input[type="password"]::-webkit-outer-spin-button, /* Chrome, Safari, Edge */
input[type="password"]::-webkit-inner-spin-button { /* Chrome, Safari, Edge */
    display: none;
    -webkit-appearance: none;
    margin: 0;
}

/* Hide the default password toggle icon in Firefox */
input[type="password"] {
  /* Firefox does not add a default toggle, but this ensures consistency */
  -moz-appearance: textfield;
}
