.submitPopupBlackBackground {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(14px);

  z-index: 999;
}

.submitNavPopup {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;

  top: 20px;

  border-radius: 20px;
  font-family: 'Poppins';
  z-index: 1002;
  text-align: center;
  height: fit-content;
  left: 0;   
  right: 0;        
  margin: 0 auto;


  width: 350px;
  padding: 10px;

  border: solid rgba(255, 255, 255, 0.05) 2px;
  background: rgba(255, 255, 255, 0.5);

  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  box-shadow: rgba(0, 0, 0, 0.5) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  cursor: pointer;
}

.submitNavPopupHeader {
  font-family: 'Poppins';
  font-weight: bold;
  margin: 0 0 10px 0;
  text-align: center;
  font-size: 22px;
}

.submitVendorNavigate, .submitAddProductNavigate {
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  margin: 3px 8px 3px 0;
  padding: 10px;
  background: black;
  color: white;

  text-align: left;
  align-content: center;
  font-size: 14px;
  font-family: 'Poppins';
  font-weight: 700;
  cursor: pointer;

  width: 100%;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.18), 2px 2px 4px rgba(0, 0, 0, 0.45);
  box-sizing: border-box;
  border: none;
} 

.submitNavBtnIcon {
  width: 20px;
  height: 20px;
  filter: invert(1)
}