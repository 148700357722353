.cartBody {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh; 

  overflow-y: auto;
  padding: 10px;
  box-sizing: border-box; 
  overflow-x: hidden;
  background-color: #C78D56;
}

.cartLoginPopup {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: calc(100% - 20px);
  top: 75px
}

.cartCardPopupDiv {
  display: flex;
  flex-direction: column;
  position: fixed;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  backdrop-filter: blur(18px);
  -webkit-backdrop-filter: blur(18px);
  font-family: 'Poppins';

  z-index: 99;
}

.cartCardPopupDiv.no-blur {
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
}

.cartHeader {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0;
  z-index: 2;
}

.cartHeadingDiv {
  white-space: nowrap;
  border-radius: 15px;
  padding: 5px 15px;
  height: 45px;

  box-sizing: border-box;
  color: rgba(255, 255, 255, 0.8);
  background-color: #6D0F0D;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
}

.cartHeading {
  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  font-weight: bold;
  margin: 0px;
  text-align: center;
  cursor: default;
}

.cartBtnDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.trackParcelBtn {
  font-family: 'Poppins', sans-serif;
  width: fit-content;
  height: fit-content;
  font-size: 14px;
  border-radius: 12px;
  color: rgba(255, 255, 255, 0.8);
  font-weight: bold;
  background-color: #6D0F0D;
  padding: 6px 12px;
  cursor: pointer;
}

.trackParcelBtn:hover {
  background-color: #F67C3A;
}

.cartNotificationIconContainer {
  position: relative;
  display: flex;
  justify-content: center;
}

.cartHistoryIcon {
  width: 33px;
  height: 33px;
}

.cartNotificationIcon {
  width: 35px;
  height: 35px;
}

.cartHistoryIcon, .cartNotificationIcon {
  margin-left: 10px;
  filter: invert(10%) sepia(53%) saturate(5796%) hue-rotate(353deg) brightness(92%) contrast(95%);
  cursor: pointer;
}

.cartNotificationBadge {
  position: absolute;
  top: -8px;    
  right: -5px;  
  padding: 2px 7px;
  border-radius: 50%;
  background-color: rgb(33, 3, 3);
  color: #FEC09A;

  font-size: 12px;
  font-weight: bold;
  font-family: 'Poppins';
}

.emptyCartDiv {
  display: flex;
  justify-content: center;
  font-family: 'Poppins';
  position: absolute;
  cursor: default;
  left: 0;
  width: 100%;
  text-align: center;
  margin: 20px auto;
}

.cartLoadingDiv {
  display: flex;
  align-items: center;
  justify-content: center;

  filter: brightness(0);
  margin: 40px auto;
  animation: spin 2s infinite linear;
}

.cartLoadingIcon {
  width: 40px;
  height: 40px;
}

.cartProductSection {
  position: relative;
  height: fit-content;
  margin-bottom: 300px;
  z-index: 3;
}

.shippingPopupContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  left: 0;
  height: 41px;
  position: fixed;
  top: 10px;
  z-index: 1004;
}

.shippingPopup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 95vw;
  max-width: 470px;
  height: fit-content;
  border-radius: 15px;
  font-family: 'Poppins';
  text-align: center;
  font-size: 14px;
  background-color: #C9DCB3;
  box-sizing: border-box;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 15px;
  z-index: 101;
  margin-top: 10px;
}

.cartTotalContainer {
  position: fixed;
  display: flex;
  flex-direction: row;
  bottom: 50px;
  left: 0px;
  width: 100%;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  height: fit-content;
  z-index: 4;
}

.cartTotalDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  background: rgba(208, 215, 215, 0.4);
  box-shadow: inset 1px 1px 2px rgba(255, 255, 255, 0.4), rgba(0, 0, 0, 0.4) 0px 30px 35px -5px, rgba(0, 0, 0, 0.2) 0px 10px 10px -5px;
  backdrop-filter: blur(40px);
  -webkit-backdrop-filter: blur(40px);

  border-radius: 20px;
  width: 92%;
  height: 180px;
}

.productsTotal, .shippingHandlingFeesTotal {
  margin-top: 10px;
  width: 95%;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: default;
}

.cartTotal {
  margin-top: -10px;
  width: 95%;
  font-family: 'Poppins';
  font-weight: bold;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: default;
}

.shippingHandlingFeesTotal {
  margin-bottom: 5px;
}

.paymentSlider {
  position: relative;
  display: flex;
  align-items: center;
  bottom: -15px;
  width: 100%;
  height: 52px;
  background-color: #F67C3A;
  box-shadow: 0px -1px 2px rgba(255, 255, 255, 0.5), 2px 2px 4px rgba(0, 0, 0, 0.2);

  border-radius: 20px 20px;
  padding: 5px;
  box-sizing: border-box;
  overflow: hidden;
}

.paymentSliderTrack {
  position: relative;
  width: 100%; /* this creates a "5px padding" on both sides */
  height: 100%;
}

.slideNotchMessage {
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-50%);
  padding: 16px 24px;
  border-radius: 15px;
  color: rgba(0, 0, 0, 0.7);
  z-index: 2000;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 15px;
  font-size: 14px;
  width: calc(100% - 20px);
  margin: 10px;
  text-align: center;
  box-sizing: border-box;
  font-family: 'Poppins';
  background-color: #C9DCB3; /* Green */
}

.paymentSliderButton {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  width: 55px;
  height: 42px;
  background-color: #FEB180;
  border-radius: 15px;
  cursor: grab;
  box-sizing: border-box;
  box-shadow: -1px -1px 2px rgba(255, 255, 255, 0.7), rgba(0, 0, 0, 0.5) 0px 10px 25px -5px, rgba(0, 0, 0, 0.3) 0px 10px 10px -5px;
}

.paymentSliderArrow {
  height: 30px;
  transform: rotate(180deg);
  filter: opacity(50%);

  user-drag: none; 
  -webkit-user-drag: none; 
  pointer-events: none;
}

.cartCardExitIconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;

  top: 10px;
  left: 10px;

  white-space: nowrap;
  border-radius: 15px;
  margin-left: 10px;
  color: rgba(255, 255, 255, 0.8);
  background-color: #B083C6;
  cursor: pointer;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
}

#cartDeleteIcon {
  width: 35px;
  height: 35px;
  padding: 5px 15px;
  filter: opacity(0.6)
}