.storeInfoContainer {
  font-family: 'Poppins';
}

.storeInfoTopSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: rgba(208, 215, 215, 0.4);
  border: solid rgba(255, 255, 255, 0.1) 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  border-radius: 20px;
  padding: 10px;
  box-sizing: border-box;
}

.percentageInfo h3 {
  margin-bottom: 20px;
}

.storesGrid {
  display: flex;
  flex-direction: column; /* Stack cards vertically since each card is 100% width */
}

.storeCard {
  border-radius: 8px;
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px 0;
}

.recordButton {
  display: block;
  margin: 0 10px 10px 10px;
  padding: 10px 20px;
  background-color: #8ad650;
  color: #000000a1;
  width: calc(100% - 20px);
  font-weight: bold;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
}

.recordButton:hover {
  background-color: #0056b3;
}

.storeRecordsSection {
  margin-top: 30px;
}

.storeRecordsTable {
  width: 100%;
  border-collapse: collapse;
}

.storeRecordsTable th,
.storeRecordsTable td {
  border: 1px solid #ddd;
  padding: 8px;
}

.storeRecordsTable th {
  background-color: #f2f2f2;
  text-align: left;
}
