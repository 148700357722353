/* Container for the multi-step form */
.vendorFormCountryUploadNote {
  position: fixed;
  bottom: 1.5%;
  width: 95%;
  z-index: 3;
  box-sizing: border-box;
  background: #E87674;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  font-family: 'Poppins'
}

.vendorFormContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: fit-content;
  width: calc(100% - 20px);
  z-index: 100;

  box-sizing: border-box;
}

.vendorFormStep1, .vendorFormStep3 {
  width: 100%
}

.formSections {
  position: relative;
  z-index: 1;
}

.createStore2Form {
  position: relative;
  width: 100%;
  z-index: 3
}

.offeringTypeQuestion, .businessInfoDiv2, .businessInfoDiv3 {
  margin: 10px;
}

.socialMediaConsentQuestion {
  padding-bottom: 5px;
}

.socialMediaConsentQuestionHeader {
  margin: 15px;
}

.socialMediaConsentQuestion {
  margin-bottom: 10px;
}

.websiteAestheticsDiv {
  position: relative;
  padding: 10px;
  gap: 10px;
  z-index: 3;
}

.vendorFormStep4Div {
  padding: 10px;
  text-align: center;
}

.offeringTypeQuestion, .businessInfoDiv2, .businessInfoDiv3, 
.serviceInfoDiv, .vendorFormStep2Container1, .vendorFormStep2Container2,
.websiteAestheticsDiv, .vendorFormStep4Div, .socialMediaConsentQuestion {
  visibility: visible;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: rgba(208, 215, 215, 0.3);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border: solid rgba(255, 255, 255, 0.1) 2px;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  border-radius: 20px;

  height: fit-content;
  font-family: 'Poppins';
  font-size: 14px
}

.serviceInfoContainer, .serviceInfoDiv {
  width: 100%;
}

.vendorFormStep2Container1 {
  position: relative;
  gap: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
  z-index: 3
}

.vendorFormStep2Container1, .vendorFormStep2Container2 {
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px
}

.serviceInfoDiv {
  margin: 10px 0;
}

.serviceInfoContainer {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.serviceInfoDiv {
  padding: 5px;
  box-sizing: border-box;
}

.businessInfoDiv3 {
  padding: 10px;
}

.offeringTypeQuestion {
  padding-bottom: 5px;
}

.servicesMessage {
  position: fixed;
  top: 5px;
  left: 0;
  
  background-color: #F5C56B;
  margin: 5px 10px;
  padding: 10px;
  border-radius: 15px;
  font-size: 14px;
  word-wrap: break-word;
  white-space: normal;

  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.2) 0px 10px 10px -5px
}

.servicesMessage, .offeringTypeBtnDiv {
  box-sizing: border-box;
  font-family: 'Poppins';
  text-align: center;
}

.offeringTypeBtnDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 5px 10px;
  height: 50px;
}

.offeringTypeButton {
  width: calc(50% - 45px)
}

.offeringTypeButton.selected, .selectedPreference{
  background-color: rgb(195, 223, 230);
  color: rgba(0, 0, 0, 0.7);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.2) 0px 10px 10px -5px;

}

#businessName2 {
  margin: 10px 0;
}

#servicesOffered {
  width: 100%;
}

#businessName2, #businessDescription2, #servicesDescription {
  width: 95%;
}

#businessName2, #servicesOffered, #phoneNumber, 
#address, #vendorFormEmail, #operationalDuration {
  background: #f5f5f5;
  height: 30px;
  border-radius: 8px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border: 0px;
  font-family: 'Poppins', sans-serif;
}

#phoneNumber, #vendorFormEmail { 
  margin-top: 0px;
  margin-bottom: 10px;
}

#address {
  margin: 0;
}

.addressLock {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  width: 30px;
  height: 30px;
  background-color: rgb(195, 223, 230);
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  cursor: pointer;
}

.addressTextPopup {
  font-family: 'Poppins';
  font-size: 14px;
  margin: 10px 0 0 0;
  border-radius: 10px;
  padding: 5px 8px;
  background-color: rgb(195, 223, 230);
}

.addressLockIcon {
  width: 20px;
  height: 20px;
}

.addressAutocompleteDiv {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  width: 100%;

}

/* Suggestions List */
.suggestionsList {
  position: absolute;
  bottom: 100%; /* Position above the input field */
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  border-top: none;
  height: fit-content;
  overflow-y: auto;
  z-index: 900; /* Ensure it appears above other elements */
  list-style: none;
  margin: 0 0 5px 0;
  padding: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Suggestion Item */
.suggestionItem {
  padding: 10px;
  cursor: pointer;
}

.suggestionItem:hover {
  background-color: #f0f0f0;
  border-radius: 8px;
}

/* Additional styling for the address input */
.addressAutocompleteContainer input {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.suggestionsList::-webkit-scrollbar {
  width: 6px;
}

/* Prevent text selection on suggestion items */
.suggestionItem {
  user-select: none;
}

.storeNameExistsDiv2 {
  font-family: 'Poppins';
  font-size: 14px;
  margin: 0px 10px 15px 10px;
  border-radius: 10px;
  padding: 5px 8px;
  background-color: #E87674;
}

#describeBrand, #goalsExpectationsOther {
  box-sizing: border-box;
  width: 100%;
  max-height: 80px;
  min-height: 80px;
}

#businessKeyWords {
  max-height: 70px;
  min-height: 70px;
}

#socialMediaLinks {
  max-height: 40px;
  min-height: 40px; 
}

#businessKeyWords, #socialMediaLinks {
  background: #f5f5f5;
  resize: none;
  border-radius: 8px;
  margin-top: 10px;
  margin-bottom: 0;
  width: 100%;
  padding: 5px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border: 0px;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box
}

#businessDescription2, #servicesDescription {
  max-height: 130px;
  min-height: 130px;
}

#businessDescription2, #servicesDescription, #describeBrand {
  box-sizing: border-box;
  background: #f5f5f5;
  resize: none;
  border-radius: 8px;
  padding: 10px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border: 0px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 10px;
}

.servicesField {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: calc(100% - 40px)
}

.businessLocationTypeDiv {
  box-sizing: border-box;
  width: 100%;
}

.operationalDurationDiv, .businessLocationTypeDiv, 
.goalsExpectationsDiv, .keyWordsDiv, .brandAestheticsDiv, 
.socialMediaLinkDiv {
  padding: 10px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.2);
}

.brandAestheticsDiv {
  width: calc(100% - 20px)
}

.goalsExpectationsOtherDiv {
  padding: 10px;
  border-radius: 10px;
  background-color: #fbbd1f71;
}

.businessLocationType {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  gap: 10px;
  width: 100%;
}

.businessLocationType button.buttonSelected, .buttonSelected {
  background-color: white; 
  color: black; 
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

#industries2 {
  margin-bottom: 10px;
  width: 95%;
}

#goalsExpectations, #operationalDuration {
  width: 100%;
  margin-top: 10px;
}

#industries2, #goalsExpectations, #brandAesthetics {
  background: #f5f5f5;
  height: 40px;
  border-radius: 8px;
  box-shadow: -2px -2px 2px rgba(255, 255, 255, 0.25), 2px 2px 4px rgba(0, 0, 0, 0.45);
  border: 0px;

  font-family: 'Poppins', sans-serif;
  overflow: scroll;
  cursor: pointer;
}

.businessLogoDiv2 {
  visibility: visible;
  display: flex;
  flex-direction: column;
  align-items: left;

  background: rgba(208, 215, 215, 0.3);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border: solid rgba(255, 255, 255, 0.1) 2px;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  border-radius: 20px;
  margin-bottom: 10px;
  height: fit-content;
}

.chooseImageButton2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  font-family: 'Poppins';
  width: fit-content;
  font-size: 14px;
  background-color: black;
  border-radius: 12px;
  color: white;
  margin: 10px 0px 10px 10px;
  padding: 6px 12px;
  cursor: pointer;
}

.createStorePlusIcon2 {
  margin-left: 10px;
  width: 15px;
  height: 15px;
  filter: invert(1);
}

.createStorePlusIcon2:hover {
  filter: invert(32%) sepia(79%) saturate(1574%) hue-rotate(5deg) brightness(102%) contrast(107%);
}

#inputField2 {
  display: none;
}

#uploadDiv2 {
  display: flex;
  height: 170px;
  width: 100%;
  margin-top: 10px;
}

#uploadedImage2 {
  height: 150px;
  width: 150px;
  position: left;
  object-fit: cover;
  border-radius: 10px;
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 20px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;

  z-index: 1;
}


.createStoreProgressBar2 {
  width: 130px;
  height: 10px;
  border-radius: 5px;
  position: absolute;
  z-index: 3;
  -webkit-appearance: none;
  appearance: none;
  bottom: 15px;
  left: 20px;
}

.createStoreProgressBar2::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

.createStoreProgressBar2::-moz-progress-bar {
  background-color: rgba(238, 238, 238, 0.651);
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

.createStoreProgressBar2::-webkit-progress-value {
  background-color: #f47a42;
  box-shadow: 1px 1px 5px 3px rgba( 0, 0, 0, 0.1 );
  border-radius: 5px;
}

.createStoreProgressBar2::-moz-progress-value {
  background-color: #f47a42;
  box-shadow: 1px 1px 5px 3px rgba( 0, 0, 0, 0.1 );
  border-radius: 5px;
}

#delete2 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  background: rgba(208, 215, 215, 0.6);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
  box-shadow: rgba(0, 0, 0, 0.4) 0px 10px 25px -5px, rgba(0, 0, 0, 0.25) 0px 10px 10px -5px;

  margin-left: 145px;
  margin-top: -5px;

  height: 30px;
  width: 30px;
  border-radius: 15px;
  cursor: pointer;
  z-index: 2;
  
}

#delete2:hover {
  filter: invert(1);
}

#deleteIcon {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 15px;
  width: 15px;
}

.completeStoreCreationBtn2 {
  position: relative; 
  right: 0;
  align-items: center;
  background-color: black;
  color: white;
  width: 100px;
  height: 30px;
  margin: 10px 5px 0px 0;
  border-radius: 10px;
  font-family: 'Poppins';
  font-weight: 500;
  cursor: pointer;
}

.completeStoreCreationBtn2:disabled {
  background-color: rgba(0, 0, 0, 0.8);
  border: none;
  cursor: not-allowed;
}

/* Headings */
.vendorFormContainer h2 {
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.vendorFormContainer h3 {
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  color: #333;
  margin-bottom: 15px;
}

.vendorFormContainer h4 {
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  color: #333;
  margin-top: 20px;
}

/* Labels */
/* .vendorFormContainer label {
  display: block;
  margin-bottom: 15px;
  color: #555;
  font-family: 'Poppins', sans-serif;
} */

/* Input Fields */
.vendorFormContainer input[type='text'],
.vendorFormContainer input[type='email'],
.vendorFormContainer input[type='tel'],
.vendorFormContainer input[type='file'],
.vendorFormContainer select,
.vendorFormContainer input[type='color'] {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}

.vendorFormContainer textarea {
  resize: vertical;
  height: 100px;
}

/* Checkbox Group */
.checkbox-group {
  display: flex;
  flex-wrap: wrap;
}

.checkbox-group label {
  width: 45%;
  margin-right: 5%;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

/* Buttons */
.buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.storelessUserNextBtn {
  right: 10px;
  position: absolute;
}

.storelessUserBackBtn {
  left: 10px;
  position: absolute;
}



.vendorFormStep2Back, .offeringTypeButton, .vendorFormContainer button {
  padding: 10px 20px;
  background-color: black;
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
}

.vendorFormStep2Back {
  display: flex;
  box-sizing: border-box;
  height: 41px;
}

.vendorFormContainer button:hover {
  background-color: #F5C56B;
  color: rgba(0, 0, 0, 0.5);
}

.vendorFormContainer button:disabled {
  background-color: rgba(0, 0, 0, 0.5);
  cursor: not-allowed;
}

/* Conditional Sections */
.conditional-section {
  margin-top: 15px;
}

/* Other Input Fields */
.other-input {
  margin-top: 10px;
}

.other-input input[type='text'] {
  width: 95%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Review Section */
.reviewSection {
  background: #f9f9f9;
  padding: 15px;
  border-radius: 4px;
  max-height: 400px;
  overflow-y: auto;
  font-family: 'Poppins', sans-serif;
}

.reviewSection ul {
  list-style-type: none;
  padding: 0;
}

.reviewSection li {
  margin-bottom: 10px;
}

.reviewSection .color-box {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  margin-right: 5px;
  border-radius: 4px;
}

.storelessUserBtnDiv, .vendorFormStep2BtnDiv, .storelessUserBtnDiv2 {
  display: flex;
  position: relative;
  font-family: 'Poppins';
  width: 100%;
  height: 50px;
  padding: 0 10px;
  box-sizing: border-box;
  margin-top: 10px;
}

.storelessUserBtnDiv2 {
  justify-content: space-between
}

.storelessUserBottomIcon {
  width: 25px;
  height: 25px;
  filter: opacity(70%);
}

.storelessUserBottomDiv {
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;

  bottom: 10px;
  right: 10px;
  height: 30px;
  width: 30px;
  padding: 5px;
  z-index: 1;
  background: #F47A42;
  border-radius: 10px;

  cursor: pointer;
}

.createStoreErrorMessage, .storeNameReservedDiv2 {
  font-family: 'Poppins';
  font-size: 12px;
  background-color: rgba(226, 70, 70, 0.873);
  border-radius: 10px;
  padding: 10px;
}

.createStoreErrorMessage {
  margin: 10px;
}

.storeNameReservedDiv2 {
  margin: 0 10px 5px 10px;
}

.deliveryPreferenceContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: rgba(208, 215, 215, 0.4);
  border: solid rgba(255, 255, 255, 0.1) 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  border-radius: 20px;
  margin: 10px 0;
  z-index: 4;

  width: 100%;
  box-sizing: border-box;
}

.socialMediaLinkDiv {
  width: 100%;
  box-sizing: border-box;
}

.socialMediaLinkInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.addSocialMediaLinkIcon {
  width: 20px;
  height: 20px;
  padding: 10px;
  border-radius: 8px;
  margin-left: 10px;
  background-color: #F5C56B;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;
  cursor: pointer;
}

#socialMediaInput {
  border-radius: 10px;
  margin-top: 0;
}

.socialMediaLinksList {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.socialMediaLinkButton {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  width: fit-content;
  padding: 5px 5px 5px 10px;
  font-size: 14px;
  border-radius: 5px;
  font-weight: bold;
  color: black;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 25px -5px, rgba(0, 0, 0, 0.259) 0px 10px 10px -5px;
}