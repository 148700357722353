.admin-container {
  padding: 10px;
  background-color: #ffcdab;
  height: 100vh;
  overflow: scroll;
}

.adminBack {
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 15px;
  background-color: #D75759;
  cursor: pointer;
  display: flex;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
}

.adminBackIcon {
  width: 35px;
  height: 35px;
  padding: 5px 15px;
  filter: opacity(0.6);
}

.adminBack:hover {
  filter: brightness(50%);
  box-shadow: none;
}

.adminBackIcon:hover {
  filter: opacity(0.8);
}

.adminDashboardContainer1, .growth-records-section, .assign-admin-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: rgba(208, 215, 215, 0.4);
  border: solid rgba(255, 255, 255, 0.1) 2px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);

  border-radius: 20px;
  box-sizing: border-box;

}

.adminHeaderSection {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
}

.admin-container-header {
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border-radius: 15px;
  padding: 5px 10px;
  margin-left: 5px;
  height: 45px;
  box-sizing: border-box;
  background-color: #D75759;
  cursor: pointer;
  display: flex;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 10px 10px -5px;
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  color: #841f21;
  font-size: 32px;
}

.admin-tabs {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 10px;
}

.admin-container h1 {
  text-align: center;
  margin-bottom: 30px;
}

.overview-section {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  flex-wrap: wrap;
}

.overview-card {
  background-color: #ffffff;
  border-radius: 8px;
  width: 100px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 10px;
  font-family: 'Poppins';
  font-size: 10px;
}

.overview-card h2 {
  margin-bottom: 10px;
  color: #333333;
  font-size: 12px;
}

.overview-number {
  font-weight: bold;
}

.overview-card p {
  font-size: 2em;
  color: #bf510c;
}

.record-button {
  display: block;
  margin: 0 10px 10px 10px;
  padding: 10px 20px;
  background-color: #8ad650;
  color: #000000a1;
  width: calc(100% - 20px);
  font-weight: bold;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
}

.record-button:hover {
  background-color: #218838;
}

.growth-records-section {
  margin-top: 20px;
}

.growth-records-section h2 {
  text-align: center;
  margin: 10px;
  font-family: 'Poppins';
  font-size: 16px;
}

.growth-records-table {
  width: calc(100% - 20px);
  border-collapse: collapse;
  margin: 10px;
  max-width: 800px;
}

.growth-records-table th, .growth-records-table td {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
  font-family: 'Poppins';
  font-size: 14px;
}

.tab-button {
  font-family: 'Poppins';
  font-size: 12px;
  border-radius: 5px;
  padding: 5px 10px;
  border: none;
  background-color: #bf510c;
  color: rgba(0, 0, 0, 0.657);
  cursor: pointer;
}

.adminActive {
  color: white;
  background-color: black;
}

.growth-records-table th {
  background-color: #f2f2f2;
}

.assign-admin-section {
  margin-top: 20px;
  text-align: center;
}

.assign-admin-section h2 {
  margin-bottom: 10px;
  font-family: 'Poppins';
  font-size: 16px;;
}

.assign-admin-section input {
  padding: 8px;
  width: calc(100% - 20px);
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  font-family: 'Poppins';
  box-sizing: border-box;
}

.assign-admin-button {
  padding: 10px 20px;
  background-color: #17a2b8;
  margin: 10px;
  color: #000000a1;
  font-weight: bold;
  width: calc(100% - 20px);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
  font-family: 'Poppins';
}

.assign-admin-button:hover {
  background-color: #138496;
}
